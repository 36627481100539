.react-tagsinput-input {
  width: 100%;
  /* padding-top: 0px !important; */
}

.react-tagsinput {
  /* padding-top: 0px; */
}

.react-tagsinput--focused {
  /* width: 100%; */
  border: 1px #0062cc solid
}

.react-tagsinput-tag {
  background-color: #E5F9FF;
  border: 1px #E5F9FF solid;
  color: #17A0C7;
  font-weight: 500;
  border-radius: 12px;

}

.react-tagsinput-tag a {
  color: #17A0C7;
}

.react-datepicker-wrapper,
.react-time-picker {
  display: flex !important;
}

.react-time-picker__wrapper {
  color: #333;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d2d6da !important;
  padding: 4px;
}

.table-user {
  /* width: 200px !important; */
}