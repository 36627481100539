.react-tagsinput-input {
  width: 100%;
  /* padding-top: 0px !important; */
}

.react-tagsinput {
  /* padding-top: 0px; */
}

.react-tagsinput--focused {
  /* width: 100%; */
  border: 1px #0062cc solid
}

.react-tagsinput-tag {
  background-color: #E5F9FF;
  border: 1px #E5F9FF solid;
  color: #17A0C7;
  font-weight: 500;
  border-radius: 12px;

}

.react-tagsinput-tag a {
  color: #17A0C7;
}

.react-tel-input .form-control {
  width: 90% !important;
  margin-left: auto !important;
}